import PubSub from 'pubsub-js';
import templateUrl from './calculator.html';
import templateTouchUrl from './touch/calculator.html';

(function () {
  angular.module('7Shop.Core')
    .component('sCalculator', {
      templateUrl: ['Touch', function (Touch) {
        return Touch.isTouch()
          ? templateTouchUrl : templateUrl;
      }],
      controller: sCalculatorCtrl
    });

  /**
   *
   * @memberOf module:"7Shop.Core"
   * @class sCalculatorCtrl
   */
  function sCalculatorCtrl(
    $scope,
    $rootScope,
    calculatorService,
    popupService,
    focusNext,
    TicketsSessions,
    googleAnalyticsService
  ) {
    var ctrl = this;
    var popUpShown = false;
    var openPopUp;
    var closePopUp;
    var pinCalculator;
    var unpinCalculator;
    var toggleCalculatorPopUp;
    var initializeCalculatorSession;

    openPopUp = function () {
      popupService.showPopup('calculator');
      // Focus first input field
      focusNext(98, true);
    };

    closePopUp = function () {
      popupService.closePopup('calculator');
    };

    pinCalculator = function () {
      calculatorService.setPinnedFlag(true);
    };

    unpinCalculator = function () {
      calculatorService.setPinnedFlag(false);
    };

    // Unpin calculator and show pop-up/Only close pop-up
    toggleCalculatorPopUp = function () {
      // Toggle pop-up flag
      popUpShown = !popUpShown;

      if (popUpShown) {
        unpinCalculator();
        openPopUp();
      } else {
        closePopUp();
      }
    };

    initializeCalculatorSession = function () {
      ctrl.calculator = calculatorService.calculator;
      ctrl.session = TicketsSessions.startNewOrGetActiveSession();
    };

    ctrl.$onInit = function () {
      initializeCalculatorSession();
    };

    // Unpin calculator and show pop-up/Pin calculator and close pop-up
    ctrl.toggleCalculatorPinned = function () {
      if (ctrl.calculator.pinned) {
        unpinCalculator();
        openPopUp();
      } else {
        pinCalculator();
        closePopUp();
      }

      googleAnalyticsService.trackEvent('Calculator', {
        event: ctrl.calculator.pinned ? 'Pin Calculator' : 'Unpin Calculator'
      });
    };
    ctrl.closePopUp = closePopUp;

    ctrl.resetCalculator = function () {
      googleAnalyticsService.trackEvent('Calculator', {
        event: 'Session Tickets'
      });

      TicketsSessions.endDirtySession();
      ctrl.session = TicketsSessions.startNewOrGetActiveSession();
      ctrl.moneyIn = 0;
      calculatorService.executePostResetHooks();
      /**
       * @event "7S:Calculator.SessionEnded"
       * @memberOf module:"7Shop.Core".sCalculatorCtrl
       */
      $rootScope.$emit('7S:Calculator.SessionEnded');
    };

    // Listen for key-press events
    const listenerToggle = PubSub.subscribe('7S:KeyboardEvent.calculator_toggle', toggleCalculatorPopUp);
    const listenerReset = PubSub.subscribe('7S:KeyboardEvent.calculator_reset', () => {
      ctrl.resetCalculator();
    });
    $scope.$on('popUp:close:calculator', function () {
      // popup is already in close process
      // so just update state
      popUpShown = false;
    });
    // check session state on popup show
    $scope.$on('popUp:show:calculator', function () {
      ctrl.session = TicketsSessions.startNewOrGetActiveSession();
    });

    ctrl.$onDestroy = () => {
      PubSub.unsubscribe(listenerToggle);
      PubSub.unsubscribe(listenerReset);
    };
  }
})();
