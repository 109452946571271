import angular from 'angular';
import userModule from '../../user';
import LogDecorator from './logDecorator';
import logService from './logService';
import runBlock from './run';

/**
 * @module "7Shop.Logger"
 */
const logger = angular.module('7Shop.Logger', [
  userModule
])
  .decorator('$log', LogDecorator)
  .service('logService', logService)
  .run(runBlock)
  .name;

export default logger;
