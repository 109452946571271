/* @ngInject */
function logService(
  NabENV,
  HooksManager,
  $q,
  $interval
) {
  this.logs = [];

  const basicAuth = (auth) => 'Basic ' + btoa(`${auth.username}:${auth.password}`);

  this.sendLogsToGraylog = () => {
    const deffered = $q.defer();
    const tempLogs = this.logs;
    // Reset logs
    this.logs = [];
    fetch(`${NabENV.graylog.url}/bulk_logs`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: basicAuth(NabENV.graylog.auth)
      },
      body: JSON.stringify(tempLogs)
    }).then(() => {
      deffered.resolve();
    })
      .catch(() => {
        deffered.resolve();
      });
    return deffered.promise;
  };
  /**
     * Send logs to graylog periodically
     */
  this.startIntervalSendLogs = function () {
    $interval(() => {
      if (this.logs.length > 0) {
        this.sendLogsToGraylog();
      }
    }, NabENV.graylog.intervalLimit, 0, false);
  };

  HooksManager.getHook('BeforeLogout').tapPromise({
    name: 'BeforeLogout.SendLogs',
    fn: this.sendLogsToGraylog
  });
}
export default logService;
