(function () {
  angular.module('7Shop.Core')
    .service('betshopService', betshopService);

  /**
   * @class betshopService
   * @memberof module:"7Shop.Core"
   */
  function betshopService(
    $http,
    SevenRoutes
  ) {
    /**
     * @typedef {Object} SevenBetshop
     * @memberof module:"7Shop.Core".betshopService
     * @property {String} address
     * @property {String} city
     * @property {NarDevice} device
     * @property {String} displayId
     * @property {Number} id
     * @property {String} name
     * @property {String} uuid
     * @property {String} [jib] - this only available when tax bih company is enabled
     */

    /**
     * @typedef {Object} NarDevice
     * @memberof module:"7Shop.Core".betshopService
     * @property {Number} deviceId
     * @property {String} name
     * @property {String} token
     * @property {String} uuid
     */

    /**
     * @type SevenBetshop
     * module:"7Shop.Core".betshopService
     */
    this.data = {};

    this.setData = function (betshop, device) {
      var betshopData = betshop;
      betshopData.device = device;
      this.data = betshopData;
    };

    /**
     * module:"7Shop.Core".betshopService
     */
    this.updateData = function (data) {
      angular.extend(this.data, data);
    };

    this.getBetshopData = function () {
      return this.data;
    };

    /**
     *
     * @memberOf module:"7Shop.Core".betshopService
     * @return {String} Betshop uuid
     */
    this.getBetshopId = function () {
      return this.data.uuid;
    };

    /**
     * Get list of devices inside betshop
     *
     * @memberOf module:"7Shop.Core".betshopService
     * @return {*}
     */
    this.getDevices = function () {
      return $http.get(SevenRoutes.mainUrl + '/shop/devices.json?uuid=' + this.data.uuid);
    };
  }
})();
