/* eslint-disable import/no-unresolved, import/no-cycle */
import { each } from 'underscore';

const SHOP_MODULES = {
  list: {
    app: {
      ng: {
        moduleName: '7Shop'
      },
      dest: 'dist/app.js'
    },
    lutrijaBih: {
      lazyLoad: {
        load: () => import(/* webpackChunkName: "src.modules.lutrija_bih" */ '../src/modules/lutrija_bih')
      }
    },
    home: {
      lazyLoad: {
        dest: 'app/src/modules/home/home.js',
        load: () => import(/* webpackChunkName: "src.modules.home" */ 'pages.home')
      },
      ng: {
        moduleName: 'shopApp'
      },
      dest: 'dist/app/src/modules/home/home.js'
    },
    installation: {
      ng: {
        moduleName: '7Shop.installation'
      },
      lazyLoad: {
        dest: 'app/modules/installation.js',
        // eslint-disable-next-line import/no-cycle
        load: () => import(/* webpackChunkName: "src.modules.installation" */ '../src/modules/installation')
      }
    },
    auth: {
      ng: {
        moduleName: '7Shop.Login'
      },
      lazyLoad: {
        // eslint-disable-next-line import/no-cycle
        load: () => import(
          /* webpackChunkName: "src.auth" */
          '../src/auth'
        )
      }
    },
    '7Shop.Partials': {
      ng: {
        moduleName: '7Shop.Partials'
      },
      lazyLoad: {
        dest: 'app/src/partials/partials.js'
      },
      dest: 'dist/app/src/partials/partials.js'
    },
    event_publisher: {
      gravityName: 'eventPublisher',
      ng: {
        moduleName: 'EventPublisher'
      },
      lazyLoad: {
        position: 'beforeAppReady',
        dest: 'src/modules/event_publisher',
        load: () => import(/* webpackChunkName: "src.modules.event_publisher" */ 'event-publisher')
      },
      dest: 'dist/app/src/modules/event_publisher.js'
    },
    iframe_pub_sub: {
      gravityName: 'iframePubSub',
      ng: {
        moduleName: '7Shop.IframePubSub'
      },
      lazyLoad: {
        dest: 'app/src/modules/iframe_pub_sub.js',
        load: () => import(/* webpackChunkName: "src.modules.iframe_pub_sub" */'iframe_pub_sub')
      },
      dest: 'dist/app/src/modules/iframe_pub_sub.js'
    },
    event_publisher_local_pub_sub: {
      gravityName: 'localPubSub',
      ng: {
        moduleName: '7Shop.EventPublisherLocalPubSub'
      },
      lazyLoad: {
        dest: 'app/src/integrations/event_publisher_local_pub_sub',
        load: () => import(/* webpackChunkName: "src.integrations.event_publisher_local_pub_sub" */ 'event_publisher_local_pub_sub')
      },
      dest: 'dist/app/src/integrations/event_publisher_local_pub_sub.js'
    },
    event_publisher_iframe_pub_sub: {
      gravityName: 'module.iframePubSub',
      ng: {
        moduleName: '7Shop.EventPublisherIframePubSub'
      },
      lazyLoad: {
        dest: 'app/src/integrations/event_publisher_iframe_pub_sub.js',
        load: () => import(/* webpackChunkName: "src.integrations.event_publisher_local_pub_sub" */ 'event_publisher_iframe_pub_sub')
      },
      dest: 'dist/app/src/integrations/event_publisher_iframe_pub_sub.js'
    },
    aml_poland: {
      gravityName: 'amlPoland',
      ng: {
        moduleName: 'AmlPoland'
      },
      lazyLoad: {
        position: 'beforeAppReady',
        dest: 'app/src/integrations/aml_poland.js',
        load: () => import(/* webpackChunkName: "src.integrations.aml_poland" */'aml_poland')
      },
      dest: 'dist/app/src/integrations/aml_poland.js'
    },
    nps: {
      gravityName: 'nps',
      ng: {
        moduleName: 'NPS'
      },
      lazyLoad: {
        dest: 'app/src/integrations/nps.js',
        load: () => import(/* webpackChunkName: "src.integrations.nps" */'nps')
      },
      dest: 'dist/app/src/integrations/nps.js'
    },
    sps: {
      gravityName: 'sps',
      ng: {
        moduleName: 'SPS'
      },
      lazyLoad: {
        dest: 'app/src/integrations/sps.js',
        load: () => import(/* webpackChunkName: "src.integrations.sps" */'sps')
      },
      dest: 'dist/app/src/integrations/sps.js'
    },
    '7cc': {
      gravityName: '7cc',
      lazyLoad: {
        position: 'afterShopAppStarted',
        load: () => import(/* webpackChunkName: "src.integrations.7cc" */'../src/modules/seven_cc')
      }
    },
    scmCC: {
      gravityName: 'scmCC',
      lazyLoad: {
        position: 'afterShopAppStarted',
        // eslint-disable-next-line import/no-cycle
        load: () => import(/* webpackChunkName: "src.modules.scmCC" */'../src/modules/seven_scm_cc')
      }
    },
    localPubSub: {
      gravityName: 'localPubSub',
      ng: {
        moduleName: 'localPubSub'
      },
      lazyLoad: {
        position: 'beforeAppReady',
        dest: 'src/integrations/localPubSub',
        load: () => import(/* webpackChunkName: "src.integrations.localPubSub" */'local-pub-sub')
      },
      dest: 'dist/app/src/integrations/localPubSub.js'
    },
    seven_devices: {
      gravityName: 'sevenDevices',
      ng: {
        moduleName: 'SevenDevices'
      },
      lazyLoad: {
        position: 'afterShopAppStarted',
        dest: 'app/src/integrations/seven_devices.js',
        load: () => import(/* webpackChunkName: "src.integrations.seven_devices" */'seven_devices')
      },
      dest: 'dist/app/src/integrations/seven_devices.js'
    },
    payout_notification: {
      gravityName: 'payoutNotification',
      ng: {
        moduleName: 'payoutNotification'
      },
      lazyLoad: {
        position: 'afterShopAppStarted',
        dest: 'app/src/integrations/payout_notification.js',
        load: () => import(/* webpackChunkName: "src.integrations.payout_notification" */'payout_notification')
      },
      dest: 'dist/app/src/integrations/payout_notification.js'
    },
    Settings: {
      ng: {
        moduleName: '7Shop.settings'
      },
      lazyLoad: {
        dest: 'app/src/settings/settings.js',
        load: () => import(/* webpackChunkName: "src.settings" */'Settings')
      },
      dest: 'dist/app/src/settings/settings.js'
    },
    Reports: {
      ng: {
        moduleName: '7Shop.Reports'
      },
      lazyLoad: {
        dest: 'app/src/reportsv2/reportsv2.js',
        load: () => import(/* webpackChunkName: "src.reports" */'Reports')
      },
      dest: 'dist/app/src/reportsv2/reportsv2.js'
    },
    Players: {
      ng: {
        moduleName: '7Shop.Players'
      },
      lazyLoad: {
        dest: 'app/src/players/players.js',
        load: () => import(/* webpackChunkName: "src.players" */'Players')
      },
      dest: 'dist/app/src/players/players.js'
    },
    CashRegister: {
      ng: {
        moduleName: '7Shop.CashRegister'
      },
      lazyLoad: {
        position: 'onGeneralModulesInit',
        dest: 'app/src/cashRegister/cashRegister.js',
        load: () => import(/* webpackChunkName: "src.cashRegister" */'CashRegister')
      },
      dest: 'dist/app/src/cashRegister/cashRegister.js'
    },
    '7Shop.Sentry': {
      lazyLoad: {
        dest: 'app/src/sentry/sentry.js'
      },
      dest: 'dist/app/src/sentry/sentry.js'
    },
    '7Shop.Touch': {
      ng: {
        moduleName: '7Shop.Touch'
      },
      lazyLoad: {
        dest: 'app/src/modules/touch.js',
        load: () => import(/* webpackChunkName: "src.modules.touch" */'7Shop.Touch')
      },
      dest: 'dist/app/src/modules/touch.js'
    },
    ticket_preview: {
      gravityName: 'ticketPreview',
      ng: {
        moduleName: '7Shop.TicketPreview'
      },
      lazyLoad: {
        position: 'afterShopAppStarted',
        dest: 'app/src/integrations/ticket_preview.js',
        load: () => import(/* webpackChunkName: "src.integrations.ticket_preview" */'ticket_preview')
      },
      dest: 'dist/app/src/integrations/ticket_preview.js'
    },
    Info: {
      ng: {
        moduleName: '7Shop.Info'
      },
      lazyLoad: {
        dest: 'app/src/modules/info/info.js',
        load: () => import(/* webpackChunkName: "src.modules.info" */'Info')
      },
      dest: 'dist/app/src/modules/info/info.js'
    },
    external_printer: {
      gravityName: 'externalPrinter',
      ng: {
        moduleName: '7Shop.ExternalPrinter'
      },
      lazyLoad: {
        dest: 'app/src/integrations/external_printer.js',
        load: () => import(/* webpackChunkName: "src.integrations.external_printer" */'external_printer')
      },
      dest: 'dist/app/src/integrations/external_printer.js'
    },
    zodiac: {
      gravityName: 'zodiac',
      ng: {
        moduleName: '7Shop.zodiac'
      },
      lazyLoad: {
        dest: 'app/src/modules/zodiac/zodiac.js',
        load: () => import(/* webpackChunkName: "src.modules.zodiac" */'../src/modules/zodiac'),
        position: 'afterShopAppStarted'
      }
    },
    Withdrawals: {
      ng: {
        moduleName: '7Shop.Withdrawals'
      },
      lazyLoad: {
        dest: 'app/src/withdrawals/withdrawals.js',
        load: () => import(/* webpackChunkName: "src.withdrawals" */'Withdrawals')
      },
      dest: 'dist/app/src/withdrawals/withdrawals.js'
    },
    Deposits: {
      ng: {
        moduleName: '7Shop.Deposits'
      },
      lazyLoad: {
        dest: 'app/src/deposits/deposits.js',
        load: () => import(/* webpackChunkName: "src.deposits" */ 'Deposits')
      },
      dest: 'dist/app/src/deposits/deposits.js'
    },
    zamba: {
      gravityName: 'zamba',
      ng: {
        moduleName: '7Shop.Zamba'
      },
      lazyLoad: {
        position: 'afterShopAppStarted',
        dest: 'app/src/integrations/zamba.js',
        load: () => import(/* webpackChunkName: "src.integrations.zamba" */'zamba')
      },
      dest: 'dist/app/src/integrations/zamba.js'
    },
    ticket_claim: {
      lazyLoad: {
        position: 'afterCustomerCardModuleReady',
        load: () => import(/* webpackChunkName: "ticket_claim" */'../src/modules/customer_cards/ticket_claim')
      }
    },
    tax_auth: {
      lazyLoad: {
        position: 'afterCustomerCardModuleReady',
        // eslint-disable-next-line import/no-cycle
        load: () => import(/* webpackChunkName: "tax_auth" */'../src/modules/customer_cards/tax_auth')
      }
    },
    BelgiumCompliance: {
      gravityName: 'belgiumCompliance',
      lazyLoad: {
        position: 'afterShopAppStarted',
        load: () => import(/* webpackChunkName: "belgium_compliance" */'../src/modules/belgium_compliance')
      }
    },
    ExternalPayoutAuthorization: {
      lazyLoad: {
        position: 'afterGravityBootstrap',
        load: () => import(/* webpackChunkName: "external_payout_authorization" */'../src/modules/external_payout_authorization')
      }
    }
  },

  filterLazyLoadModules: function (position) {
    var result = [];

    each(this.list, function (module, key) {
      if (module.lazyLoad && module.lazyLoad.position === position) {
        module.id = key;
        result.push(module);
      }
    });

    return result;
  },

  getModules: function () {
    each(this.list, function (module, key) {
      module.id = key;
    });

    return this.list;
  },

  getModule: function (moduleName) {
    return this.list[moduleName];
  }
};

export default SHOP_MODULES;
