import angular from 'angular';
import translate from 'angular-translate';
import router from '@uirouter/angularjs';
import 'angular-uuid';
import { each } from 'underscore';
import '@nsftx/seven-ng-client-core/src/seven/sevenServices';
import '@nsftx/seven-ng-socket/dist/nab-ng-socket';
import '@nsftx/seven-ng-client-core/dist/seven-ng-client-core';
import ngLib from '../modules/ng_lib';
import commonModule from '../common';
// eslint-disable-next-line import/no-cycle
import modulesModule from '../modules';
import userModule from '../user';
import sevenMoneyOverride from './filters/sevenMoneyOverrideFilter';
import CloudflareService from './services/cloudflareService';
import CmsFormsParserService from './services/cmsFormsParserService';
import GravityFormsProvider from './gravity/gravityFormsProvider';
import GravityLayoutsProvider from './gravity/gravityLayoutsProvider';
import GravitySettingsProvider from './gravity/gravitySettingsProvider';
import sGravityControlComponent from './components/sGravityControlComponent';
import errorParser from './services/errorParser';
import SevenNcmSvc from './services/sevenNcmSvc';
import sEquals from './directives/sEqualsDirective';
import KeyEventsSvc from './keyEvents/keyEventService';
import sOverlayMenuLabelComponent from './overlay/overlay_menu_label/sOverlayMenuLabelComponent';

(function () {
  /**
   * @module "7Shop.Core"
   */
  angular.module('7Shop.Core', [
    translate,
    router,
    ngLib,
    'SevenClientCore',
    'SevenClient.Config',
    commonModule,
    modulesModule,
    userModule
  ]).run(function (
    $rootScope,
    Shortcuts
  ) {
    /**
       *
       * @param e
       * @param eventData
       * @param eventData.productId
       * @param eventData.data
       * @param eventData.data.shortcuts
       * @param eventData.data.color
       * @param eventData.data.ticketCheckFormatter
       */
    function registerShortcuts(e, eventData) {
      if (eventData.data.shortcuts) {
        each(eventData.data.shortcuts, function (shortcut) {
          if (!shortcut.category) {
            shortcut.category = eventData.productId;
          }
          Shortcuts.registerShortcuts([shortcut]);
        });
      }
    }
    /**
     *
     */
    $rootScope.$on(
      '7S:Widget.UpdateProductSettings',
      registerShortcuts
    );
    /**
     *
     */
    $rootScope.$on(
      '7S:Widget.UpdateSettings',
      registerShortcuts
    );
  }).factory('KeyEventsSvc', KeyEventsSvc)
    .filter('sevenMoneyOverride', sevenMoneyOverride)
    .service('CloudflareService', CloudflareService)
    .service('CmsFormsParserService', CmsFormsParserService)
    .service('errorParser', errorParser)
    .service('SevenNcmSvc', SevenNcmSvc)
    .provider('GravityForms', GravityFormsProvider)
    .provider('GravityLayouts', GravityLayoutsProvider)
    .provider('GravitySettings', GravitySettingsProvider)
    .directive('sEquals', sEquals)
    .component('sGravityControl', sGravityControlComponent)
    .component('sOverlayMenuLabel', sOverlayMenuLabelComponent);
})();
