/* eslint-disable angular/window-service, angular/document-service, no-console */
import 'jquery';
import 'underscore';
import 'moment';
import 'moment/locale/sr';
import 'moment/locale/ro';
import 'socket.io-client';
import 'jwt-decode';
import 'angular';
import 'angular-animate';
import '@uirouter/angularjs';
import 'angular-translate';
import 'angular-uuid';
import '@nsftx/seven-ng-socket/dist/nab-ng-socket';
import 'ng-file-upload/dist/ng-file-upload';
import '@nsftx/seven-ng-client-core/dist/seven-ng-client-core';
import '@nsftx/seven-ng-print-service/dist/ng-print-service';
import './modules/ng_lib';
import '../vendor/polyfills';
import '../vendor/ui-bootstrap-custom-tpls-2.5.0.min';
import '../bootstrap';
import './app';

console.log('[7Shop] Displaying url details before document is ready', {
  location: window.location
});

// make sure the DOM is fully loaded before bootstrapping angular app
angular.element(document).ready(function () {
  var LOCAL_DATA = {};
  var ShopEnvironment = window.ShopEnvironment;
  var injector = angular.injector(['ng']);
  var $http = injector.get('$http');
  var titleFromNwArgs;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const entries = urlSearchParams.entries();
  const entriesArray = Array.from(entries);
  const params = {};

  entriesArray.forEach((element) => {
    params[element[0]] = element[1];
  });

  console.log('[7Shop] Displaying url details', {
    location: window.location,
    urlSearchParams,
    entriesArray,
    params
  });

  window.ShopEnvironment.init();

  function initializeWebkit() {
    // eslint-disable-next-line
    var gui = __non_webpack_require__('nw.gui'); // https://webpack.js.org/api/module-variables/#__non_webpack_require__-webpack-specific
    var argObject = {};
    var commandLineArgs = gui.App.argv;
    var argValue;

    // remove saved passwords https://github.com/nwjs/nw.js/issues/5944
    window.chrome.passwordsPrivate.getSavedPasswordList(function (passwords) {
      if (passwords.length) {
        window.chrome.passwordsPrivate.removeSavedPassword(passwords[0].loginPair);
      }
    });

    // disable password saving https://github.com/nwjs/nw.js/issues/5944
    window.chrome.privacy.services.passwordSavingEnabled.set({ value: false });

    function setWindowDimensions() {
      var win = gui.Window.get();
      var width = parseInt(argObject.windowWidth, 10) || 1024;
      var height = parseInt(argObject.windowHeight, 10) || 768;
      var xOffset = parseInt(argObject.xOffset, 10) || 0;
      var yOffset = parseInt(argObject.yOffset, 10) || 0;
      var windowMaximized = _.has(argObject, 'start-maximized');

      if (!windowMaximized) {
        win.unmaximize();
        win.resizeTo(width, height);
        win.x = xOffset;
        win.y = yOffset;
      } else {
        win.maximize();
      }
    }

    commandLineArgs.forEach(function (clArg) {
      argValue = clArg.split('=');
      argObject[argValue[0].substr(2)] = argValue[1];
    });
    // Set window title passed from NAR so they can find us later
    titleFromNwArgs = argObject.appName;

    console.log('[7Shop] Displaying command line arguments', {
      commandLineArgs,
      argObject
    });

    // Set standalone app dimensions0
    if (_.has(argObject, 'standalone')) {
      setWindowDimensions(argObject);
    }
  }

  // for backwards comapability with fedora24
  if (typeof process !== 'undefined' && process.versions['node-webkit']) {
    // node-webkit detected
    initializeWebkit();
  }

  /**
   * Create angular shared module services shared between all our apps
   */
  function setUpSharedModule(data) {
    angular.module('SevenClient.Config').constant('LocalAppConfig', data.LocalAppConfig);
    angular.module('SevenClient.Config').constant('SevenRoutes', {
      mainUrl: data.LocalAppConfig.sevenUrl,
      cmUrl: data.LocalAppConfig.sevenSocketUrl,
      localAdminConfig: data.env.api.localAdminConfig,
      localAdminLogin: data.env.api.localAdminLogin,
      NARLoginRoute: data.env.NARLoginRoute ? data.env.NARLoginRoute.url : '',
      bonusBaseUrl: data.env.bonus.baseUrl || '',
      assetsUrl: data.env.assetsServer ? data.env.assetsServer.url : '',
      fiscalServiceUrl: data.env.fiscalService ? data.env.fiscalService.url : '',
      taxAuth: data.env.taxAuth ? data.env.taxAuth : ''
    });
  }

  function setLocalDataNgConstant(LocalData) {
    angular.module('SevenClient.Config').constant('LocalData', LocalData);
  }

  function setNabENVNgConstant(env) {
    angular.module('SevenClient.Config').constant('NabENV', env); // we need environment
  }

  function setApplicationEnvironment(env, config) {
    var envQueryParam = ShopEnvironment.urlFlags ? ShopEnvironment.urlFlags.env : false;
    env = env || envQueryParam || 'staging';

    ShopEnvironment.setEnv(ShopEnvironment.ENVS[env.toUpperCase()]);
    ShopEnvironment.baseDCUrl = config
      .api
      .baseDCUrl;
  }

  function setTitle(title) {
    var newTitle = title;
    const lsTitle = localStorage.getItem('window.title');
    console.log('[7Shop] Setting title', {
      paramTitle: title,
      lsTitle
    });

    if (!newTitle && lsTitle) {
      newTitle = lsTitle;
    }

    if (!newTitle) {
      // if we still did not find defualt winow title set default one
      console.log('[7Shop] App title not found, setting 7Shop0 as default one');
      newTitle = '7Shop0';
    }

    localStorage.setItem('window.title', newTitle);
    window.document.title = newTitle;
  }

  function getTitle() {
    return window.document.title;
  }

  function init() {
    // eslint-disable-next-line no-undef
    var configPath = `data/config.${GIT_HASH}.json`;
    const appTitleFromUrl = window.ShopEnvironment.getUrlFlag('appTitle');
    console.log('[7Shop] detected app title', {
      appTitleFromUrl,
      titleFromNwArgs
    });

    setTitle(appTitleFromUrl || titleFromNwArgs);
    // why replace? cause grunt-cache-bust won't find file if there is no slash on first place
    //  same is done in bootstrap/modules.js
    $http.get(configPath).then(function (configDataResponse) {
      var localDataStrategy = ShopEnvironment.getFlagState(ShopEnvironment.FLAGS.BETSHOP_DATA_SOURCE);
      var loaderInterface = window.ShopDeviceDataLoaders[localDataStrategy.value];

      // we have environment set already by build process so set data
      LOCAL_DATA.env = configDataResponse.data;
      window.SEVEN_LOCAL_DATA = LOCAL_DATA;

      // some builders (RPM) still want to write their own version,
      // they are writing version to .global.version node,
      // if version is set, use it.
      // this will be removed soon
      if (LOCAL_DATA.env.global && LOCAL_DATA.env.global.version) {
        LOCAL_DATA.env.version = LOCAL_DATA.env.global.version;
      }

      setApplicationEnvironment(LOCAL_DATA.env.type, LOCAL_DATA.env);
      setNabENVNgConstant(LOCAL_DATA.env);

      loaderInterface
        .isInstalled()
        .then(function () {
          loaderInterface.getData({ title: getTitle() }).then(function (response) {
            // device is installed
            // proceed with setting local data
            LOCAL_DATA.LocalData = response.device.data;
            LOCAL_DATA.LocalAppConfig = response.appConfig.data;

            setUpSharedModule(LOCAL_DATA);
            setLocalDataNgConstant(LOCAL_DATA.LocalData);

            angular.bootstrap(
              document,
              ['7Shop']
            );
          }).catch(function (err) {
            console.log('[7Shop] Fail loading data with loader', err); // eslint-disable-line
          });
        }).catch(function () {
          var installationModeFlag = {};
          // set fake data or one of intercpetors will fail,
          LOCAL_DATA.LocalAppConfig = {
            sevenUrl: 'fakeMainUrl'
          };
          installationModeFlag.id = ShopEnvironment.FLAGS.NEED_INSTALLATION;
          installationModeFlag.active = true;
          ShopEnvironment.setFlagsState([installationModeFlag]);
          // we must have those constants avaiable with fake data in installation mode
          setUpSharedModule(LOCAL_DATA);
          setLocalDataNgConstant({});

          angular.bootstrap(
            document,
            ['7Shop']
          );
        });
    }).catch(function (err) {
      console.log('[7Shop] Fail fetching config.json', err); // eslint-disable-line
    });
  }

  init();
});
