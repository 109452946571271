import router from '@uirouter/angularjs';
import translate from 'angular-translate';
import '@nsftx/seven-ng-client-core/src/seven/sevenServices';
import '@nsftx/seven-ng-socket/dist/nab-ng-socket';
import '@nsftx/seven-ng-client-core/dist/seven-ng-client-core';
import ngLib from '../modules/ng_lib';
import sInputSwitchDirective from './directives/sInputSwitch/sInputSwitchDirective';
import sAutoSelectText from './directives/sAutoSelectText';
import modalSvcConfig from './modal/modalConfigProvider';
import modalSvc from './modal/modalOldService';
import sWidgetInclude from './widget/widgetIncludeDirective';
import NotificationsService from './notifications/notificationsService';
import bitwiseFilter from './filter/bitwiseFilter';
import helpersService from './helpers/helpersService';
import sClock from './directives/clockDirective';
import sGaTrackEvent from './directives/gaTrackEventDirective';
import ChipComponent from './components/chip/chipComponent';

/**
  * Common directives, services, filters.
  * @module "7Shop.Common"
  */
angular.module('7Shop.Common', [
  router,
  translate,
  'SevenClientCore',
  'SevenClientLib',
  ngLib
]).directive('sInputSwitch', sInputSwitchDirective)
  .directive('sAutoSelectText', sAutoSelectText)
  .directive('sWidgetInclude', sWidgetInclude)
  .directive('sClock', sClock)
  .directive('sGaTrackEvent', sGaTrackEvent)
  .service('NabNotifications', NotificationsService)
  .service('helpersService', helpersService)
  .filter('bitwise', bitwiseFilter)
  .provider('modalSvcConfig', modalSvcConfig)
  .factory('modalSvc', modalSvc)
  .component('sChip', ChipComponent);
